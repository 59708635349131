#Map 
{
    width: 100%;
    height: 100%;
    z-index: -1;
}

.MapOverlay 
{
    z-index: 1000;
}

.MediaSlider 
{
    max-height: 250px;
}

.MediaSlider * 
{
    height: 100%;
}

.PointInfoImage > *
{
    height: 100%;

}

#NewPointPin 
{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -100%);
}

.SmoothFloat 
{
    -webkit-animation: glide 2s ease-in-out alternate infinite;
	        animation: glide 2s ease-in-out alternate infinite;
    transform: translate(0, -2px);
}


@-webkit-keyframes glide  {
    from {
       transform: translate(0, 0);
    }
    
    to {
       transform: translate(0, -4px);
    }
 }
 @keyframes glide  {
    from {
        transform: translate(0, 0);
     }
     
     to {
        transform: translate(0, -4px);
     }
 }