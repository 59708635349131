#SignupCard {
    max-width: 600px;
    min-width: 300px;
    width: 95%;
    margin: 80px auto;
}

#VerificationCard {
    max-width: 500px;
    min-width: 300px;
    width: 95%;
    margin: 80px auto;
}

#LoginCard {
    max-width: 500px;
    min-width: 300px;
    width: 95%;
    margin: 80px auto;
}

#ForgotCard {
    max-width: 600px;
    min-width: 300px;
    width: 95%;
    margin: 80px auto;
}

#NewPasswordCard {
    max-width: 600px;
    min-width: 300px;
    width: 95%;
    margin: 80px auto;
}