/* Custom Skeleton Line Height and Margin */
.custom-skeleton ion-skeleton-text {
    line-height: 13px;
  }
  
  .custom-skeleton ion-skeleton-text:last-child {
    margin-bottom: 5px;
  }

  .image{
    margin-left: 2px;
  } 

  .hori{
    overflow-x: scroll;
    overflow-y: hidden;
    height:130px;
    width: 500vw;
  }
